import axios from 'axios'

class SalaryActionApi {
  async get(type) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .get(`/counting/services_salaries/type/${type}`)
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async add(name, type) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`/counting/services_salaries/add`, {
        name,
        type,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async edit(name, id) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .put(`/counting/services_salaries/edit`, {
        name,
        id,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async remove(id) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .delete(`/counting/services_salaries/delete/${id}`)
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }
}

export default new SalaryActionApi()
